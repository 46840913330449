<template>
  <div class="home">
   <div class="hometop">
     <div class="hometop-h1">
        <div class="hometop-h1-left">
          <img src="@/assets/svg/u43104.svg" alt="">
          <span>工单执行进度看板</span>
        </div>
        <div class="hometop-h1-right">
          <div class="time">
            <div class="timeleft">
              <img  src="@/assets/svg/u43102.svg" alt="">
            </div>
            
            <img src="@/assets/svg/u43103.svg" alt="">

            <div class="djs">2024-08-06 13:44:15</div>
          </div>
      
         <div class="line">
            <img src="@/assets/svg/u43101p001.svg" alt="">
         </div>

          <div class="heng">
           
            <img src="@/assets/svg/u43105.svg" alt="">
            <img src="@/assets/svg/u43105.svg" alt="">
            <img src="@/assets/svg/u43105.svg" alt="">
            <div class="hengimg">
              <img src="@/assets/svg/u43108.svg" alt="">
            </div>
            
          
          </div>
        </div>
     </div>
     <div class="hometop-h2">
       <img src="@/assets/svg/u43100.svg" alt="">
     </div>
     <!-- <img src="@/assets/svg/u43109.svg" alt=""> -->
   </div>
   
   <div class="homemain">
    <el-table 
    :data="tableData" 
    style="width: 100%" 
    :stripe="true" 
    :fit="true"
    header-cell-class-name="baitablehearer"
    row-class-name="baitablerow"
    cell-class-name="baitablecell"
    >
    <el-table-column prop="sn" label="生产编号" />
    <el-table-column prop="status" label="客户名称"  />
    <el-table-column prop="gsn" label="方案名称"  />
    <el-table-column prop="gname" label="交付时间"  />
    <el-table-column prop="gspec" label="生产进度" :width="300" >
      <el-steps  :active="2" align-center>
        <el-step title="Step 1" />
        <el-step title="Step 2" />
        <el-step title="Step 3" />
      </el-steps>
    </el-table-column>
    <el-table-column prop="route" label="生产工厂"  />
    <el-table-column prop="rsn" label="生产车间"  />
    <el-table-column prop="rname" label="生产线" />
    <el-table-column prop="jnum" label="生产工序"  />
    <el-table-column prop="hnum" label="产品数量"  />
    <el-table-column prop="bhnum" label="报工数量"  />
  </el-table>
   </div>
  </div>
</template>

<script lang="ts" setup>
const tableData = [
  {sn: 'GDBH202408061',status: '客户一',gsn: '方案一',gname: '2024-08-07',gspec: '进度一',route: '工厂一',rsn: '车间一',rname: '生产线一',jnum: '工序一',hnum: '50',bhnum: '0'},
  {sn: 'GDBH202408061',status: '客户一',gsn: '方案一',gname: '2024-08-07',gspec: '进度一',route: '工厂一',rsn: '车间一',rname: '生产线一',jnum: '工序一',hnum: '50',bhnum: '0'},
  {sn: 'GDBH202408061',status: '客户一',gsn: '方案一',gname: '2024-08-07',gspec: '进度一',route: '工厂一',rsn: '车间一',rname: '生产线一',jnum: '工序一',hnum: '50',bhnum: '0'},
  {sn: 'GDBH202408061',status: '客户一',gsn: '方案一',gname: '2024-08-07',gspec: '进度一',route: '工厂一',rsn: '车间一',rname: '生产线一',jnum: '工序一',hnum: '50',bhnum: '0'},
  {sn: 'GDBH202408061',status: '客户一',gsn: '方案一',gname: '2024-08-07',gspec: '进度一',route: '工厂一',rsn: '车间一',rname: '生产线一',jnum: '工序一',hnum: '50',bhnum: '0'},
  {sn: 'GDBH202408061',status: '客户一',gsn: '方案一',gname: '2024-08-07',gspec: '进度一',route: '工厂一',rsn: '车间一',rname: '生产线一',jnum: '工序一',hnum: '50',bhnum: '0'},
  {sn: 'GDBH202408061',status: '客户一',gsn: '方案一',gname: '2024-08-07',gspec: '进度一',route: '工厂一',rsn: '车间一',rname: '生产线一',jnum: '工序一',hnum: '50',bhnum: '0'},
  {sn: 'GDBH202408061',status: '客户一',gsn: '方案一',gname: '2024-08-07',gspec: '进度一',route: '工厂一',rsn: '车间一',rname: '生产线一',jnum: '工序一',hnum: '50',bhnum: '0'},
  {sn: 'GDBH202408061',status: '客户一',gsn: '方案一',gname: '2024-08-07',gspec: '进度一',route: '工厂一',rsn: '车间一',rname: '生产线一',jnum: '工序一',hnum: '50',bhnum: '0'},
  // {sn: 'GDBH202408061',status: '客户一',gsn: '方案一',gname: '2024-08-07',gspec: '进度一',route: '工厂一',rsn: '车间一',rname: '生产线一',jnum: '工序一',hnum: '50',bhnum: '0'},
 
]

</script>

<style lang="less">
.home {
  width: 100%;
  padding: 20px 20px;
}
.hometop {
  width: 100%;
  // height: 100px;
  // background: pink;
  &-h1 {
    width: 100%;
    // height: 90px;
    // background: plum;
    display: flex;
    &-left {
      flex-shrink: 0;
      position: relative;
      margin-top: 5px;
      span {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        font-size: 35px;
        font-weight: bold;
        letter-spacing: 20px;
        text-align: center;
        line-height: 80px;
        color: #12E4E8;
      }
    }
    &-right {
      flex: 1;
      position: relative;
      .time {
        width: 100%;
        display: flex;
        
        .timeleft {
          flex: 1;
          margin-bottom: 10px;
          position: relative;
          img {
            width: 100%;
            position: absolute;
            bottom: 0;
            vertical-align: bottom;
          }
        }
        
        img:nth-child(2){
          flex-shrink: 0;
          margin-left: 10px;
        }
        .djs {
          position: absolute;
          top: 0;
          right: 40px;
          font-size: 20px;
          text-align: center;
          line-height: 36px;
          color: #12E4E8;
        }
      }
      .line {
        position: absolute;
        text-align: right;
        top: 22px;
        right: 19px;
        img {
          width: 100%;
        }
      }
      .heng {
        margin-top: 10px;
        display: flex;
        justify-content: flex-end;
        img:nth-child(1),img:nth-child(2),img:nth-child(3) {
          flex-shrink: 0;
          height: 39px;
          margin-right: -25px;
        }
        .hengimg {
          flex: 1;
          img {
            width: 100%;
            height: 39px;
          }
        }
        
      }
    }
  }
  &-h2 {
    width: 100%;
    text-align: right;
    img {
      width: 100%;
      margin-top: -20px;
    }
  }
}

.homemain {
  width: 100%;

  // background: pink;
  margin-top: 20px;
  border: 3px solid #3293DF;
  padding: 10px;
}

.el-table {
  --el-table-border-color: transparent !important;
}
.baitablehearer {
  background-color: #005EA9 !important;
  color: white !important;
  text-align: center !important;
  border-right: 1px solid #031958 !important;
}
.baitablehearer:last-child {
  border-right: none !important;
}

.el-table__body .baitablerow:nth-child(odd) {
  .baitablecell {
    background-color: #031958 !important;
    color: white !important;
    text-align: center;
  }
  
}
.el-table__body .baitablerow:nth-child(even) {
  .baitablecell {
    background-color: #04335F !important;
    color: white !important;
    text-align: center;
  }
}

.el-step__title.is-process {
  color: white !important;
}
.el-step__head.is-process {
  border-color:#8d8e90 !important;
  color: #8d8e90 !important;
  // background-color: #8d8e90 !important;
}
</style>
