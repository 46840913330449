<template>
  
  <router-view/>
</template>

<script lang="ts" setup>
import { debounce } from "lodash";

const _ = (window as any).ResizeObserver;
(window as any).ResizeObserver = class ResizeObserver extends _ {
  constructor(callback: (...args: any[]) => void) {
    callback = debounce(callback, 100);
    super(callback);
  }
};

</script>
<style lang="less">
*{
  margin: 0;
  padding: 0;
}
div {
  box-sizing: border-box;
}
#app {
  width: 100%;
  height: 100vh;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // text-align: center;
  color: white;
  background: #031958;
  overflow: auto;
}

/* 自定义整个滚动条 */
::-webkit-scrollbar {
  width: 12px; /* 设置滚动条的宽度 */
  background-color: #f9f9f9; /* 滚动条的背景色 */
}
 
/* 自定义滚动条轨道 */
::-webkit-scrollbar-track {
  background: #e1e1e1; /* 轨道的背景色 */
  border-radius: 10px; /* 轨道的圆角 */
}
 
/* 自定义滚动条的滑块（thumb） */
::-webkit-scrollbar-thumb {
  background-color: #c1c1c1; /* 滑块的背景色 */
  border-radius: 10px; /* 滑块的圆角 */
  border: 2px solid #ffffff; /* 滑块边框 */
}
 
/* 当滑块悬停或活动时的样式 */
::-webkit-scrollbar-thumb:hover {
  background: #a8a8a8; /* 悬停或活动状态下滑块的背景色 */
}
// nav {
//   padding: 30px;

//   a {
//     font-weight: bold;
//     color: #2c3e50;

//     &.router-link-exact-active {
//       color: #42b983;
//     }
//   }
// }
</style>
